import dayjs from "dayjs"

import { FC, useEffect, useState } from "react"

import useInView from "@/lib/hooks/useInView"
import { useMembershipBooking } from "@/lib/hooks/useMembershipBooking"

import { useAuth } from "@/Context/auth.context"
import { useProducts } from "@/Context/products.context"

import { Calendar } from "./ui/calendar"
import { DialogContent, DialogFooter } from "./ui/dialog"

// gid://shopify/Cart/Z2NwLWFzaWEtc291dGhlYXN0MTowMUhYV0Y2MzQ1RzhSWkszVlk3WlYxTUU5Mg
// gid://shopify/Cart/Z2NwLWFzaWEtc291dGhlYXN0MTowMUhYV0Y4QVZOUzYxNjhYR1hCR1JXNU03Rg
const memebershipLenght: Record<string, number> = {
  "gid://shopify/ProductVariant/44556557254951": 1,
  "gid://shopify/ProductVariant/44556559253799": 1,
  "gid://shopify/ProductVariant/44556559483175": 1,
  "gid://shopify/ProductVariant/44556555354407": 3,
  "gid://shopify/ProductVariant/44556558958887": 3,
  "gid://shopify/ProductVariant/44556561088807": 3,
  "gid://shopify/ProductVariant/44556559384871": 7,
  "gid://shopify/ProductVariant/44556558893351": 7,
  "gid://shopify/ProductVariant/44556561416487": 7,
}

/**
 *
 * selected booing date - 07-06-2024
 * for 1 day pass the start date should be 07-06-2024
 * for 3 day pass the start date can be 04-06-2024 - 07-06-2024
 * for 7 day pass the start date can be 01-06-2024 - 07-06-2024
 */

const SelectStartDateDialog: FC<{
  // eslint-disable-next-line no-unused-vars
  bookMembership: (date: Date, assignImmediately?: string) => Promise<void>
  recurring?: boolean
}> = ({ bookMembership, recurring }) => {
  const [date, setDate] = useState<Date | undefined>(new Date())
  const [loading, setLoading] = useState(false)
  const { classesReserved } = useAuth()
  const { membershipItems } = useMembershipBooking()
  const [isVisible, divRef] = useInView()
  const { products, setFetchData } = useProducts()
  const [productsInCart, setProductsInCart] = useState<
    {
      id: string
      title: string
      tags: string[]
      variantId: string
      amount: string
    }[]
  >([])
  const [minDate, setMinDate] = useState<Date>(new Date())
  const [maxDate] = useState<Date | undefined>(undefined)

  const [assignImmediately, setAssignImmediately] = useState<
    string | undefined
  >(undefined)

  //stash

  useEffect(() => {
    if (isVisible) {
      setFetchData(true)
    }
  }, [isVisible])

  useEffect(() => {
    if (classesReserved.length && assignImmediately) {
      const lastClass = classesReserved[classesReserved.length - 1]
      const bookingDate = dayjs(new Date(lastClass.classStartDate))
      const daysBeforeBooking = memebershipLenght[assignImmediately]
      //deduct the days from the booking date
      const md = bookingDate.subtract(daysBeforeBooking, "day").toDate()
      setMinDate(md)
      // const minDate = bookingDate.add(1, "day").toDate()
    }
  }, [classesReserved, assignImmediately])

  useEffect(() => {
    if (products && membershipItems) {
      setProductsInCart(
        products.filter((p) => membershipItems.includes(p.variantId)),
      )
    }
    if (membershipItems.length === 1) {
      setAssignImmediately(membershipItems[0])
    }
  }, [products, membershipItems])

  const dialogClose = () => {
    document.getElementById("closeDialog")?.click()
  }

  return (
    <div>
      <DialogContent>
        <h1
          ref={divRef}
          className="font-display max-w-[320px] mb-[30px] text-2xl"
        >
          Select Start Date
        </h1>
        {productsInCart.length > 1 && (
          <div className="my-2">
            <p className="font-bold">
              Choose a membership to be assigned immediately
            </p>
            <select
              className="w-full min-w-[280px] max-w-[280px] bg-[#F1F2F2] border-0 rounded-sm h-[34.99px] px-[16px]"
              name="setImmediately"
              id="setImmediately"
              onChange={(e) => {
                setAssignImmediately(e.target.value)
              }}
            >
              {productsInCart.map((p) => (
                <option key={p.variantId} value={p.variantId}>
                  {p.title}
                </option>
              ))}
            </select>
          </div>
        )}
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          className="mx-auto border rounded-md w-min"
          fromDate={minDate}
          toDate={maxDate}
          data-cy="select-start-date-dialog-calendar"
        />
        <DialogFooter className="py-4">
          <button
            aria-label="Close Dialog"
            data-cy="select-start-date-dialog-submit"
            onClick={async () => {
              if (!date) {
                return
              }
              if (recurring && !assignImmediately) {
                return
              }
              setLoading(true)
              await bookMembership(date, assignImmediately)
              setLoading(false)
              dialogClose()
            }}
            disabled={
              !date ||
              loading ||
              (productsInCart.length > 1 && !assignImmediately)
            }
            className="hover:bg-brand w-full border-2 border-text hover:border-brand bg-text  hover:text-text disabled:opacity-40 disabled:pointer-events-none transition-colors text-brand duration-150 ease-out rounded-[8px] font-bold text-md -skew-x-[21deg] inline-block pt-[20px]  pb-[16px] pl-[63px] translate-y-[25px] group pr-[53px] "
          >
            <div className="skew-x-[21deg] flex  justify-center uppercase items-center gap-[14px]">
              <span>{loading ? "Loading..." : "Confirm Booking"}</span>
            </div>
          </button>
        </DialogFooter>
      </DialogContent>
    </div>
  )
}

export default SelectStartDateDialog
